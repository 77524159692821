import { memo } from 'react';
import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';
import { useTranslations } from '@pepita-react/i18n';

import { Autocomplete } from 'src/components/Autocomplete';

import type { autocompleteConfigType } from '../config/formConfigTypes';

import type { FieldType } from '../types';

export const AutocompleteField = memo(function AutocompleteField({
  fieldConfig,
  onChange,
}: FieldType<autocompleteConfigType>) {
  const { trans } = useTranslations();

  const isInternational = fieldConfig.endpoint === 'international';
  const isHoliday = fieldConfig.endpoint === 'holiday';
  const isMyHome = fieldConfig.endpoint === 'myHome';
  const getUrl = () => {
    if (isInternational) {
      return '/api-next/geography/autocomplete/?service=autointernational&query={query}';
    }

    if (isHoliday) {
      return '/api-next/geography/autocomplete/?service=autoarea&contract=1&query={query}';
    }

    if (isMyHome) {
      return '/api-mh/v2/autocomplete/address?q={query}';
    }

    return '/api-vi/v2/autocomplete/address?q={query}';
  };

  return (
    <Autocomplete
      inputIcon={fieldConfig.icon}
      inputPlaceholder={
        fieldConfig.label
          ? // i18n-extract-disable-next-line
            trans(fieldConfig.label)
          : undefined
      }
      onChange={(values) => {
        if (!onChange) return;

        if (isInternational && values[0]['id']) {
          return onChange('city', values[0]['id']);
        }

        if (isHoliday && values[0]['id'] && values[0]['type'] === 4) {
          return onChange('area', values[0]['id']);
        }

        if (
          isHoliday &&
          values[0]['id'] &&
          values[0]['type'] === ENTITY_TYPE.city
        ) {
          return onChange('city', values[0]['id']);
        }

        if (values[0]['label']) {
          onChange('a', values[0]['label']);
        }

        if (values[0]['center']) {
          onChange(
            'q',
            `${values[0]['center'].lat},${values[0]['center'].lng}`
          );
        }
      }}
      url={getUrl()}
    />
  );
});
